.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
}

  .node.leaf.collapsed {
      display: none;
    }

  .node.disabled > * {
    color: gray;
    cursor: not-allowed;
  }

  .node.match-in-children.hide .node-label {
        opacity: 0.5;
      }

  .node.focused {
    background-color: #f4f4f4;
  }

.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}

.toggle::after {
    content: ' ';
  }

.toggle.collapsed::after {
    content: '+';
  }

.toggle.expanded::after {
    content: '-';
  }

.searchModeOn .toggle {
  display: none;
}

.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;
}

.checkbox-item.simple-select, .radio-item.simple-select {
    display: none;
  }
