.treeSelect {
  position: relative;
}
.treeSelect__wrapper {
  position: relative;
  display: flex;
  gap: var(--spacing-4);
}

.treeSelect__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  cursor: pointer;
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-300);
  border-radius: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  width: 100%;
  &[aria-disabled="true"] {
    cursor: not-allowed;
    background-color: var(--color-disabled-bg);
    border: 1px solid var(--color-disabled-border);
    border-radius: var(--border-radius-base);
  }

  &[data-state="open"] {
    border-color: var(--color-focus);
    box-shadow: 0 0 0 1px var(--color-focus);
  }
}

.treeSelect__triggerButton {
  flex-shrink: 0;
  padding: 0 !important;
  color: hsl(0, 0%, 80%) !important;
  & svg {
    fill: currentColor !important;
  }
}
.treeSelect__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 4px 11px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: var(--color-white);
  z-index: 60;
  margin-top: 6px;
  &[data-state="open"] {
    padding: var(--spacing-2);
  }
  overflow: auto;
  max-height: 300px;
}

.treeSelect__tagGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &:has(> *) {
    padding-top: var(--spacing-2);
  }
}
.treeSelect__label {
  display: block;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-text-base);
  margin-bottom: var(--spacing-1);
}
.treeSelect__input {
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.treeSelect__compact {
  width: 215px;
}
