.Input {
  border: none;
  color: var(--color-gray-900);
  padding: 9px 10px;
  border-radius: var(--border-radius-base);

  &:disabled {
    border: none;
    background-color: transparent;
  }
  &:focus {
    border: 1px solid #ced4da;
    outline-color: var(--color-secondary-500);
  }
  &::placeholder {
    color: var(--color-neutral-500);
  }

  &:invalid,
  &[aria-invalid="true"] {
    border-color: var(--color-red-900);
  }
}
