.chip {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-neutral-900);

  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}
