.user {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  overflow: hidden;
}

.userTitle {
  font-size: var(--font-size-text-base);
  line-height: var(--line-height-text-base);
  color: var(--color-neutral-contrast-300);
  font-weight: var(--font-weight-medium);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userText {
  color: var(--color-neutral-400);
  font-size: var(--font-size-text-sm);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userContent {
  display: flex;
  flex-direction: column;
}

.userContent[data-minimized="true"] {
  display: none;
}
