.sidebarwrapper {
  width: var(--sidebar-width);
  transition: width var(--sidebar-transition);
  &[data-minimized="true"] {
    width: var(--sidebar-width-minimized);
  }
  flex-shrink: 0;
  position: relative;

  @media print {
    display: none;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 15px 10px;
  position: relative;
  min-height: 100%;
  background-color: var(--color-neutral-contrast-900);
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%);

  @media print {
    display: none;
  }
}

.logoContainer {
  display: grid;
}

.logoSmall,
.logo {
  margin: 0;
  // equal height container and overlapping images for smooth toggle animation
  height: 58px;
  grid-row: 1;
  grid-column: 1;
}

.logoSmall {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;

  [data-minimized="true"] & {
    opacity: 1;
  }
}

.logo {
  opacity: 1;
  transition: opacity 0.1s ease-in-out 0.1s;

  [data-minimized="true"] & {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
}

.menu {
  position: relative;
}

.footer {
  margin-top: auto; // align to bottom
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footerActions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: var(--spacing-4);
  padding-bottom: var(--spacing-5);
  border-bottom: 1px solid var(--color-neutral-100);
}

.sidebarwrapper[data-minimized="true"] .version {
  display: none;
}

.end {
  margin-top: var(--spacing-20);
  display: flex;
  & > div {
    width: 100%;
  }
}

.toggleButtonContainer {
  position: fixed;
  top: 50vh;
  left: calc(var(--sidebar-width) - 12px);
  transition: all var(--sidebar-transition);
  display: flex;
  align-content: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: white;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%);
  border-radius: var(--border-radius-md);
  z-index: 1;

  &:hover {
    background-color: var(--color-primary-500);

    svg {
      color: white;
    }
  }
}

.sidebarwrapper[data-minimized="true"] .toggleButtonContainer {
  left: calc(var(--sidebar-width-minimized) - 12px);
}

.toggleButtonIcon {
  transition: transform 0.125s ease-in-out;
}

.sidebarwrapper[data-minimized="true"] .toggleButtonIcon {
  transform: scaleX(-1);
}

.menuItemLink {
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}

.menuItemText {
  font-size: var(--font-size-text-md);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.2s ease-in-out;
}

[data-minimized="true"] .menuItemText {
  opacity: 0;
}

.subMenu {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: var(--spacing-6);
  transition: margin 0.2s ease-in-out;
}

.subMenuIndent {
  margin-left: var(--spacing-4);
}

.verticalChevron {
  transition: transform 0.125s ease-in-out;
  margin-left: auto;

  &.verticalFlip {
    transform: scaleY(-1);
  }
}

.sidebarwrapper[data-minimized="true"] .verticalChevron {
  position: absolute;
  left: 18px;
  transform: scale(0.75);

  &.verticalFlip {
    transform: scaleY(-1) scale(0.75);
  }
}

.TopbarButton {
  flex-shrink: 0;
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background-color: var(--color-background);
  color: #333333;
  padding: var(--spacing-2);
  border-radius: 100%;
  cursor: pointer;

  &[data-minimized="true"] {
    display: none;
  }

  &:hover {
    background-color: var(--color-neutral-200);
  }
}

.TopbarIconButton {
  width: 34px;
  height: 34px;
  transition: all var(--transition);
}
