.PageHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: var(--margin-layout);
}

.PageHeaderTitle {
  display: inline-block;
  font-size: var(--font-size-text-2xl);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-text-2xl);
}

.PageHeaderTitleSuffix {
  display: inline-block;
  font-size: var(--font-size-text-lg);
  line-height: var(--line-height-text-xl);
  font-weight: var(--font-weight-semibold);
  padding-left: var(--spacing-2);
}

.actions {
  display: flex;
  gap: 20px;

  button {
    height: fit-content;
  }
}
