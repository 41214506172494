.Wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 0.5rem;
}
.Root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 19px;
  height: 19px;

  background: #ffffff;

  border: 1px solid var(--color-neutral-300);
  border-radius: 3px;
  cursor: pointer;
  z-index: 1;

  &[data-state="checked"] {
    color: var(--color-secondary-500);
  }

  &[data-state="indeterminate"] {
    color: var(--color-neutral-800);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-disabled-bg);
    border: 1px solid var(--color-disabled-border);
    border-radius: var(--border-radius-base);
  }
}

.Indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: inherit;
}

.Label {
  display: block;
  font-size: 15px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
