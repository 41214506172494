.dateRange {
  position: relative;

  &[data-state="open"] .dateRange__trigger {
    border-color: var(--color-focus);
    box-shadow: 0 0 0 1px var(--color-focus);
  }
}
.dateRange__wrapper {
  position: relative;
  display: flex;
  gap: var(--spacing-4);
}

.dateRange__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  cursor: pointer;
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-300);
  border-radius: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  width: 100%;

  &[aria-disabled="true"] {
    cursor: not-allowed;
    background-color: var(--color-disabled-bg);
    border: 1px solid var(--color-disabled-border);
    border-radius: var(--border-radius-base);
  }
}
.dateRange__triggerButton {
  flex-shrink: 0;
  padding: 0 !important;
  color: hsl(0, 0%, 80%) !important;
  & svg {
    fill: currentColor !important;
  }
}
.dateRange__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
  gap: var(--spacing-6);
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 4px 11px rgba(0, 0, 0, 0.1);
  padding: var(--spacing-3);
  border-radius: 3px;
  background-color: var(--color-white);
  z-index: 60;
}

.dateRange__tagGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &:has(> *) {
    padding-top: var(--spacing-2);
  }
}
.dateRange__label {
  display: block;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-text-base);
  margin-bottom: var(--spacing-1);
}
.dateRange__input {
  border: none;
  width: 100%;
  cursor: default;

  &:focus {
    outline: none;
  }
}

.dateRange__contentLabel {
  display: block;
  font-size: var(--font-size-text-base);
  text-wrap: nowrap;
}
.dateRange__contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2);

  // ellipsis on period button text (is free text input field)
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
.dateRange__seperator {
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  height: 90%;
}
.dateRange__contentItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  width: 100%;
}
