.skeleton {
  background-color: var(--color-skeleton-bg);
  border-radius: 4px;
  display: inline-block;
  margin: 0 0.5rem;
  height: 1rem;
  width: 100%;
  min-width: 100px;
  animation: pulse 1s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}
