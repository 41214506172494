.FormControl {
  display: flex;
  flex-direction: column;

  & label {
    font-size: var(--font-size-text-base);
    font-weight: var(--font-weight-medium);
    color: var(--color-neutral-800);
    text-align: left;
    margin-bottom: 4px;
  }

  & input[data-invalud="true"],
  & textarea[data-invalud="true"] {
    border: 1px solid var(--color-red-700);
  }

  & input {
    border: 1px solid var(--color-neutral-300);
  }
}

.FormControlError {
  color: var(--color-red-700);
  font-size: var(--font-size-text-sm);
  font-weight: var(--font-weight-medium);
}

.FormControlRequired {
  color: var(--color-red-700);
  font-size: var(--font-size-text-sm);
  font-weight: var(--font-weight-medium);
  margin-left: var(--spacing-1);
}
