.applicationModeLabel {
  display: block;
  margin-top: 1px;
  font-weight: var(--font-weight-medium);
  user-select: none;
}

.applicationModeBox {
  flex-shrink: 0;
  background-color: var(--color-background);
  color: #333333;
  border-radius: var(--border-radius-6xl);
  padding: var(--spacing-2) var(--spacing-3);
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  font-size: var(--font-size-text-sm);
  margin-left: auto;
  margin-right: auto;

  &[data-minimized="true"] {
    padding: var(--spacing-2);

    .applicationModeLabel {
      display: none;
    }
  }

  svg {
    color: var(--color-secondary-500);
    width: 20px;
    height: 20px;
  }
}
