.authenticatedUser {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chevronRight {
  width: 20px;
  height: 20px;

  &[data-minimized="true"] {
    display: none;
  }
}
