.OptionSelector {
  display: flex;
  padding: var(--spacing-1);
  border: 1px solid var(--color-neutral-200);
  border-radius: 26px;
  gap: var(--spacing-1\.5);
}

.OptionSelectorOptionContainer {
  min-width: 0;
  white-space: nowrap;
}

.OptionSelectorOption {
  display: flex;
  color: var(--color-primary-contrast-50);
  background-color: var(--color-primary-contrast-900);
  padding: var(--spacing-1\.5) var(--spacing-4);
  border-radius: var(--border-radius-6xl);
  cursor: pointer;
  gap: var(--spacing-1);
  transition: all var(--transition);
  user-select: none;

  &:hover {
    color: var(--color-secondary-500);
  }
}

.OptionSelectorOptionSelected {
  background-color: var(--color-secondary-500);
  color: var(--color-neutral-contrast-900);

  &:hover {
    color: var(--color-neutral-contrast-900);
  }
}

.OptionSelectorVariant {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-6xl);
  background-color: var(--color-neutral-contrast-900);
}

.OptionSelectorVariantOption {
  display: flex;
  align-items: center;
  color: var(--color-primary-contrast-10);
  background-color: transparent;
  border-radius: var(--border-radius-6xl);
  padding: var(--spacing-1\.5) var(--spacing-4);
  cursor: pointer;
}

.OptionSelectorVariantOptionSelected {
  background-color: var(--color-secondary-500);
  color: var(--color-neutral-contrast-900);

  &:hover {
    color: var(--color-neutral-contrast-900);
  }
}
