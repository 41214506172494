.tree {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tree__node {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-1);
}

.tree__expand {
  color: var(--color-neutral-600) !important;
  flex-shrink: 0;
}

.tree__noResults {
  text-align: center;
  width: 100%;
}
