.KeyboardShortcutsDialog {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: var(--spacing-10);
}

.KeyboardShortcutsDialogKeyMapItem:not(:first-child) {
  margin-top: var(--spacing-5);
}

.KeyboardShortcutsDialogList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.KeyboardShortcutsDialogTitle {
  color: var(--color-secondary-600);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-2);
}

.KeyboardShortcutsDialogLine {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-10);
  align-items: center;
  padding-left: var(--spacing-4);
  margin: var(--spacing-3) 0;
}

.KeyboardShortcutsDialogKeys {
  padding-left: var(--spacing-4);
}

.KeyboardShortcutsDialogKey {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 22px;
  padding: 3px 6px 1px 6px;
  font-size: var(--font-size-text-xs);
  background-color: var(--color-neutral-100);
  border-bottom: 2px solid var(--color-neutral-400);
  border-radius: var(--border-radius-base);
  user-select: none;
  letter-spacing: -0.25px;
  color: var(--color-neutral-600);

  &:not(:first-child) {
    margin-left: var(--spacing-2);
  }
}
