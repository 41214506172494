@page {
  size: landscape;
}

.graphContainer {
  position: relative;
  // max-width: 1000px;
}

.labelContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 12px;
  font-size: 12px;
}

.graphLabel {
  display: flex;
  gap: 6px;
  cursor: pointer;
  &[aria-disabled="true"] {
    cursor: default;
  }

  @media print {
    display: none;
  }
}

.graphNoData {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: 1;
  span {
    background: var(--color-skeleton-bg);
    border-radius: var(--border-radius-base);
    padding: 8px 16px;
    opacity: 0.8;
  }
}

.graphLoader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-skeleton-bg);
  align-items: center;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.graphSpinner {
  width: 70px;
  height: 70px;
  margin: auto;
}
