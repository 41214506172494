.Textarea {
  border: none;
  color: var(--color-gray-900);
  padding: var(--spacing-3);
  border-radius: var(--border-radius-base);
  resize: none;

  &:disabled {
    border: none;
    background-color: transparent;
    resize: none;
    overflow: hidden;
  }

  &:focus {
    outline-color: var(--color-focus);
  }

  &::placeholder {
    color: var(--color-neutral-500);
  }

  &:invalid,
  &[aria-invalid="true"] {
    border-color: var(--color-red-900);
  }
}
