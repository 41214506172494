.Overlay {
  background-color: var(--color-primary-contrast-50);
  opacity: 0.5;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: var(--z-index-50);
}

.Content {
  background-color: var(--color-white);
  border-radius: 6px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: fit-content;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: var(--z-index-60);
  overflow: auto;

  &:focus {
    outline: none;
  }
}
.Header {
  position: relative;
}

.Title {
  margin: 0;
  font-weight: var(--font-weight-semibold);
  color: var(--color-neutral-900);
  font-size: 17px;
  padding-right: 60px; // avoid overlapping X button
}

.TitleMargin {
  margin-bottom: 20px;
}

.Description {
  margin: 6px 0 20px;
  color: var(--color-neutral-700);
  font-size: 15px;
  line-height: 1.5;
}
.Actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

button.CloseButton {
  position: absolute;
  top: -4px;
  right: 0;
  padding: 10px;
  cursor: pointer;
  & svg {
    color: var(--color-neutral-500);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
