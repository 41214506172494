.PageLoader {
  margin-top: 90px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageLoaderSpinner {
  opacity: 0;
  animation: fadein 0.25s ease-in-out;
  animation-fill-mode: forwards;
  // animation-delay: 0.75s;

  svg {
    color: var(--color-secondary-300);
    display: block;
    max-width: 60px;
    margin: 0 auto;
    margin-bottom: var(--spacing-2);
  }

  span {
    font-size: var(--font-size-text-sm);
  }
}

.PageLoaderError {
  color: var(--color-red-400);
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: var(--font-size-text-md);
  line-height: var(--line-height-text-lg);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
