.Avatar {
  margin: 0;
  padding: 0;
  display: inline-flex;
  position: relative;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
}

.AvatarClip {
  position: absolute;
  width: var(--size);
  height: var(--size);
  top: calc((var(--stroke-width) + var(--gap-width)) / 2);
  left: calc((var(--stroke-width) + var(--gap-width)) / 2);
  clip-path: circle(calc(var(--size) / 2) at center);
}

.AvatarRing {
  position: absolute;
  inset: 0;
  border: var(--stroke-width) solid var(--avatar-border-color, var(--color-neutral-100));
  border-radius: 50%;
}

.AvatarFallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-800);
  font-size: var(--font-size-text-md);
  line-height: var(--line-height-text-xs);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}
