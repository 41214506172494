.Menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: var(--spacing-6);
  padding: 0;
}

.Menu_link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--font-size-text-base);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition);
  color: #b2b2b2;
  cursor: pointer;
  user-select: none;

  &[data-active="true"],
  &:hover,
  &:focus,
  &:active {
    color: var(--color-primary-600);
  }

  svg {
    flex-shrink: 0;
  }
}
