.comments {
  margin-top: 60px;

  h2 {
    margin-bottom: 15px;
  }

  .commentsInner {
    background-color: #fff;
    padding: 30px;
    border-radius: 6px;
  }

  .commentInput {
    margin-bottom: 45px;
    width: 100%;

    textarea {
      width: 100%;
    }
  }

  .commentSection {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;

    &:last-child {
      border: none;
    }

    .commentSectionComment {
      margin-top: 20px;
      margin-left: 15px;

      .commentSectionCommentDetails {
        font-weight: 500;
        margin-bottom: 15px;

        span {
          margin-left: 15px;
          font-weight: 400;
          color: #b2b2b2;
        }
      }
    }
  }
}
