.Breadcrumbs {
  display: flex;
  align-items: center;
  min-height: 25px;
  gap: var(--spacing-2\.5);
  list-style: none;
  padding: 0;
  margin: 0;
}

.Breadcrumbs li {
  display: flex;
  align-items: center;
  gap: var(--spacing-2\.5);
}

.Breadcrumbs a,
.Breadcrumbs span {
  font-size: var(--font-size-text-md);
  font-weight: var(--font-weight-normal);
  color: var(--color-neutral-600);
}

.Breadcrumbs a {
  transition: color var(--transition);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-secondary-600);
  }
}

.Breadcrumbs svg {
  width: var(--spacing-3\.5);
  height: var(--spacing-3\.5);
  color: var(--color-neutral-600);
}
