.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
}

  .tag:focus-within {
    background-color: #e9e9e9;
    border-color: #a0a0a0;
  }

.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.tag-remove.readOnly,
  .tag-remove.disabled {
    cursor: not-allowed;
  }

.tag-remove:focus {
    color: #3c3c3c;
  }
