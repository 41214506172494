.tooltipContent {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius-lg);
  max-width: 300px;
  padding: var(--spacing-6);
  z-index: var(--z-index-50);
  p {
    margin: 0;
  }
  z-index: 1;
}

.tooltipArrow {
  z-index: 2;
  --arrow-size: 26px;
  // Shadow arround tooltip arrow
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    height: var(--arrow-size);
    width: var(--arrow-size);
    bottom: calc(var(--arrow-size) / 2 * -1);
    left: 0;
    background: transparent;
    transform: rotate(45deg);
  }

  // Exact shadow depends on tooltip position
  &.position-right {
    &:before {
      left: 8px;
      box-shadow: -5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    }
  }

  &.position-left {
    &:before {
      left: -32px;
      box-shadow: 5px -5px 15px -5px rgba(0, 0, 0, 0.3);
    }
  }

  &.position-top {
    &:before {
      left: -13px;
      top: -32px;
      box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    }
  }

  &.position-bottom {
    &:before {
      left: -13px;
      top: 8px;
      box-shadow: -5px -5px 15px -5px rgba(0, 0, 0, 0.3);
    }
  }
}
