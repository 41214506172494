.node > label {
  cursor: pointer;
  margin-left: 2px;
}

.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}

.tag-item {
  display: inline-block;
  margin: 4px;
}

.tag-item .search {
    border: none;
    border-bottom: solid 1px #ccc;
    outline: none;
  }

.tag-item:last-child {
  margin-right: 4px;
}
