.SelectControl {
  border-width: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.SelectControlLabel {
  font-size: var(--font-size-text-base);
  font-weight: var(--font-weight-medium);
  color: var(--color-neutral-800);
  text-align: left;
  margin-bottom: 4px;
}

.SelectControlSelect {
  max-width: var(--spacing-40);
  min-width: 180px;
}
