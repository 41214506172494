.UserMenu__Button {
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  cursor: pointer;
  outline: none;
}

.UserMenu__Overlay {
  position: fixed;
  inset: 0;
  background: var(--color-neutral-900);
  opacity: 0.33;
}

.UserMenu__Card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: var(--border-radius-base);
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 4px 11px rgba(0, 0, 0, 0.1);
  padding: var(--spacing-4);
  gap: var(--spacing-8);
  width: var(--spacing-72);
  margin-bottom: var(--spacing-2);
}

.UserMenu__ModeLabel {
  padding-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  font-weight: 400;
  color: var(--color-neutral-800);
}

.UserMenu__Panel {
  position: absolute;
  z-index: 50;
}
