/* component styles */
.hide:not(.match-in-children) {
  display: none;
}
.react-dropdown-tree-select .dropdown {
    position: relative;
    display: table;
  }
.react-dropdown-tree-select .dropdown .dropdown-trigger {
      padding: 4px;
      line-height: 20px;
      max-height: 200px;
      display: inline-block;
      overflow: auto;
      border: 1px solid rgb(185, 185, 185);
    }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
        cursor: pointer;
      }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom::after {
          content: '\25BC';
          vertical-align: middle;
          color: #3c3c3c;
          margin-right: 2px;
        }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after {
          content: '\25B2';
          vertical-align: middle;
          color: #3c3c3c;
          margin-right: 2px;
        }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
          cursor: not-allowed;
        }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom::after {
            color: rgb(185, 185, 185);
          }
.react-dropdown-tree-select .dropdown .dropdown-content {
      position: absolute;
      padding: 4px;
      z-index: 1;
      background: white;
      border-top: rgba(0, 0, 0, .05) 1px solid;
      -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
              box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
    }
.react-dropdown-tree-select .dropdown .dropdown-content .search {
        width: 100%;
        border: none;
        border-bottom: solid 1px #ccc;
        outline: none;
      }
.react-dropdown-tree-select .dropdown .dropdown-content ul {
        margin: 0;
        padding: 0;
      }
